<template>
  <div>
    <PageTitle title="Действия пользователей" icon />
    <user-actions />
  </div>
</template>

<script>
import UserActions from '@/components/viewLogs/userActions'
import PageTitle from '@/components/PageTitle.vue'

export default {
  name: 'UserLogs',
  components: { UserActions, PageTitle },
}
</script>
